.ForumTableMain {
  background-color: #F5F5F5;
  height: 100%;
  width: 100%;
  margin-top: 115px;
  position: fixed;
  scrollbar-width: 16px;
  scrollbar-color: #fff;
}

.MainContentDiv {
  font-weight: bold;
  font-size: large;
  background-color: #F5F5F5;
  height: 100%;
  width: 100%;
}

.ForumListDiv {
  background-color: white;
  height: calc(100% - 115px);
  width: 100%;
  overflow: auto;
}

.ForumListDiv::-webkit-scrollbar {
  background-color: white;
  width: 14px;
}

.ForumListDiv::-webkit-scrollbar-track {
  background-color: white;
}

.ForumListDiv::-webkit-scrollbar-thumb {
  background-color: #D3D3D3;
  border-radius: 16px;
  border: 4px solid white;
}

.ForumListDiv::-webkit-scrollbar-button {
  display:none;
}

.ForumHeadingDiv {
  color: #FFFFFF;
  background-color: rgba(0,0,0,0.9);
  box-shadow: 0 5px 10px #888888;
  padding: 15px 0px;
  margin-bottom: 20px;
}

.ForumHeading {
  font-family: Arial;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 0px;
}

.ForumTableHeader {
  background-color: #FCC601;
  box-shadow: 0 1px 8px #888888;
}

.ForumTableHeader > tr > th {
  font-family: Arial;
  font-weight: bold;
}

.ForumTableBody > tr {
  background-color: ghostwhite;
}

.ForumButtonsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ForumTableButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.75);
  color: white;
  margin: 15px 5px;
  min-height: 35px;
  min-width: 35px;
  border-radius: 8px;
  box-shadow: 0 5px 10px #888888;
}

.PostTitle:hover {
  cursor: pointer;
}

.ForumTableButton:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.9);
}

@media screen and (min-width: 1024px) {
  .ForumTableMain {
    position: fixed;
    margin-top: 115px;
  }

  .ForumListDiv {
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
  }
}
.LoginFormMainDiv {
  display: flex;
  justify-content: center;
  background-color: ghostwhite;
  min-width: 350px;
  min-height: 350px;
  width: 96%;
  margin: 0px auto;
  box-shadow: 0 5px 10px #888888;
  padding: 20px;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginButton {
  border-radius: 4px;
  background-color: rgba(0,0,0,0.75);
  box-shadow: 0 2px 10px #888888;
  color: white;
  min-width: 100px;
  min-height: 45px;
  font-family: Arial;
  font-weight: 10;
  font-size: 14px;
}

.LoginButton:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.9);
}